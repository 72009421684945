import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IourTeam, IourTeamStatus } from 'src/app/components/our-team/model';
import { CryptService } from '../crypt.service';

import { servicesUrl } from '../servicesUrl';

@Injectable({
  providedIn: 'root',
})
export class OurTeamService {
  constructor(
    private c: CryptService,
    private http: HttpClient,
    private urlApi: servicesUrl
  ) {}

  public imageUrl(): string {
    return this.urlApi.url + '/images/post/';
  }

  public GetMenu(check: string, FbrnId: string, lang: string) {
    const formData = new FormData();
    formData.append('check', check);
    formData.append('FbrnId', this.c.Decrypt(sessionStorage.getItem('brnId')));
    formData.append('Lang', lang);
    return this.http
      .post(this.urlApi.url + '/our_team/getMenu', formData)
      .pipe();
  }

  public sysProperty(check: any, MType: string, Lang: string) {
    const formData = new FormData();
    formData.append('check', check);
    formData.append('MType', MType);
    formData.append('Lang', Lang);
    return this.http
      .post(this.urlApi.url + '/our_team/sysProperty', formData)
      .pipe();
  }

  public GetPostMetaVal(
    check: any,
    postId: string,
    MType: string,
    Lang: string
  ) {
    const formData = new FormData();
    formData.append('check', check);
    formData.append('MType', MType);
    formData.append('Lang', Lang);
    formData.append('postId', postId);
    return this.http
      .post(this.urlApi.url + '/our_team/GetPostMetaVal', formData)
      .pipe();
  }

  public OrderPost(check: string, order: string) {
    const formData = new FormData();
    formData.append('check', check);
    formData.append('order', order);
    return this.http
      .post(this.urlApi.url + '/our_team/OrderPost', formData)
      .pipe();
  }

  public MainSys(check: string, FbrnId: string, lang: string) {
    const formData = new FormData();
    formData.append('check', check);
    formData.append('FbrnId', this.c.Decrypt(sessionStorage.getItem('brnId')));
    formData.append('Lang', lang);
    return this.http
      .post(this.urlApi.url + '/our_team/getMainSys', formData)
      .pipe();
  }

  public SettingScreen(MType: string, LANG: string) {
    const formData = new FormData();
    formData.append('MType', MType);
    formData.append('LANG', LANG);
    return this.http
      .post(this.urlApi.url + '/our_team/SettingScreen', formData)
      .pipe();
  }

  public OurTeamStatus(type, lang): Observable<IourTeamStatus[]> {
    return this.http
      .get<IourTeamStatus[]>(
        this.urlApi.url + '/our_team' + '/OurTeamStatus/' + type + '/' + lang
      )
      .pipe();
  }

  public GetOurTeamWhereSection(id, lang): Observable<IourTeam[]> {
    return this.http
      .get<IourTeam[]>(this.urlApi.url + '/our_team/' + id + '/' + lang)
      .pipe();
  }

  public GetOurTeamLang(id): Observable<IourTeam[]> {
    return this.http
      .get<IourTeam[]>(this.urlApi.url + '/our_team/lang/')
      .pipe();
  }

  public GetsectionOurTeam(lang, brn, type): Observable<IourTeam[]> {
    return this.http
      .get<IourTeam[]>(
        this.urlApi.url + '/our_team/section/' + lang + '/' + brn + '/' + type
      )
      .pipe();
  }

  public OurTeamPost(
    check,
    TeamId,
    MType,
    ParentId,
    TeamName,
    PostTitle,
    TeamIsHome,
    TeamDsc,
    TeamDetails,
    TeamImage,
    FbrnId,
    FusrId,
    Fstate,
    LangId,
    forms,
    file,
    checkFile
  ) {
    const formData = new FormData();
    formData.append('check', check);
    formData.append('PostId', TeamId);
    formData.append('MTYPE', MType);
    formData.append('ParentId', ParentId);
    formData.append('PostName', TeamName);
    formData.append('PostTitle', PostTitle);
    formData.append('PostIsHome', TeamIsHome);
    formData.append('PostDsc', TeamDsc);
    formData.append('PostDetails', TeamDetails);
    formData.append('PostImage', TeamImage);
    formData.append('FbrnId', FbrnId);
    formData.append('FusrId', FusrId);
    formData.append('Fstate', Fstate);
    formData.append('LangId', LangId);
    formData.append('forms', forms);
    formData.append('file', file);
    formData.append('checkFile', checkFile);
    return this.http
      .post(this.urlApi.url + '/our_team/' + 'OurTeamPost', formData)
      .pipe();
  }

  public deleteProd(check, TeamId, langId, namefile) {
    const formData = new FormData();
    formData.append('check', check);
    formData.append('PostId', TeamId);
    formData.append('LangId', langId);
    formData.append('namefile', namefile);
    return this.http
      .post(this.urlApi.url + '/our_team/' + 'OurTeamPost', formData)
      .pipe();
  }

  public getServices(
    check: string,
    srvId: string,
    comName: string,
    comDomain: string,
    services: string,
    serviceDisc: string,
    comPhone: string,
    comEmail: string,
    state: string,
    FbrnId: string,
    userId: string
  ) {
    const bodyFormData = new FormData();
    bodyFormData.append('check', check);
    bodyFormData.append('srvId', srvId);
    bodyFormData.append('comName', comName);
    bodyFormData.append('comDomain', comDomain);
    bodyFormData.append('service', services);
    bodyFormData.append('serviceDisc', serviceDisc);
    bodyFormData.append('comPhone', comPhone);
    bodyFormData.append('comEmail', comEmail);
    bodyFormData.append('state', state);
    bodyFormData.append('FbrnId', FbrnId);
    bodyFormData.append('usrId', userId);
    return this.http
      .post(this.urlApi.url + '/our_team/' + 'getService', bodyFormData)
      .pipe();
  }

  public getRegStd(
    check: string,
    regStdId: string,
    lvlName: string,
    stdName: string,
    lvlDate: string,
    stdPhone: string,
    stdEmail: string,
    state: string,
    FbrnId: string,
    usrId: string
  ) {
    const bodyFormData = new FormData();
    bodyFormData.append('check', check);
    bodyFormData.append('regStdId', regStdId);
    bodyFormData.append('lvlName', lvlName);
    bodyFormData.append('stdName', stdName);
    bodyFormData.append('lvlDate', lvlDate);
    bodyFormData.append('stdPhone', stdPhone);
    bodyFormData.append('stdEmail', stdEmail);
    bodyFormData.append('state', state);
    bodyFormData.append('FbrnId', FbrnId);
    bodyFormData.append('usrId', usrId);
    return this.http
      .post(this.urlApi.url + '/our_team/' + 'getRegStd', bodyFormData)
      .pipe();
  }

  public getRegStd2(
    check: string,
    regstdId: string,
    lvlName: string,
    stdName: string,
    StdGender: string,
    StdNationality: string,
    StdPlaceBirthday: string,
    StdDateBirthday: string,
    StdTypeQualification: string,
    StdAverage: string,
    StdDateQualification: string,
    StdPlaceQualification: string,
    StdGovernorate: string,
    StdSchool: string,
    StdPlaceIssue: string,
    StdDateIssue: string,
    StdTypeStudy: string,
    StdAddress: string,
    StdLvlMain: string,
    StdTypeIdCard: string,
    StdIdCardNumber: string,
    StdPlaceIdCard: string,
    stdPhone: string,
    stdEmail: string,
    state: string,
    FbrnId: string,
    userId: string
  ) {
    const bodyFormData = new FormData();
    bodyFormData.append('check', check);
    bodyFormData.append('regStdId', regstdId);
    bodyFormData.append('lvlName', lvlName); //الكلية
    bodyFormData.append('stdName', stdName); //اسم الطالب
    bodyFormData.append('StdGender', StdGender); //النوع
    bodyFormData.append('StdNationality', StdNationality); //الجنسية
    bodyFormData.append('StdDateBirthday', StdDateBirthday); //تاريخ الميلاد
    bodyFormData.append('StdPlaceBirthday', StdPlaceBirthday); //مكان الميلاد
    bodyFormData.append('StdTypeQualification', StdTypeQualification); //نوع الثانوية العامة
    bodyFormData.append('StdAverage', StdAverage); // المعدل
    bodyFormData.append('StdDateQualification', StdDateQualification); //سنه الحصول على الشهادة
    bodyFormData.append('StdPlaceQualification', StdPlaceQualification); //مكان الحصول على الشهادة
    bodyFormData.append('StdGovernorate', StdGovernorate); //المحافظة
    bodyFormData.append('StdSchool', StdSchool); //المدرسة
    bodyFormData.append('StdPlaceIssue', StdPlaceIssue); //مكان الاصدار للشهادة
    bodyFormData.append('StdDateIssue', StdDateIssue); //تاريخ الاصدار للشهادة
    bodyFormData.append('StdTypeStudy', StdTypeStudy); //نوع الدارسة
    bodyFormData.append('StdAddress', StdAddress); // عنوان السكن
    bodyFormData.append('StdLvlMain', StdLvlMain); //التخصص
    bodyFormData.append('StdTypeIdCard', StdTypeIdCard); //نوع الهوية
    bodyFormData.append('StdIdCardNumber', StdIdCardNumber); //رقم الهوية
    bodyFormData.append('StdPlaceIdCard', StdPlaceIdCard); //جهة اصدار الهوية
    bodyFormData.append('stdPhone', stdPhone);
    bodyFormData.append('stdEmail', stdEmail);
    bodyFormData.append('state', state);
    bodyFormData.append('FbrnId', FbrnId);
    bodyFormData.append('usrId', userId);
    return this.http
      .post(this.urlApi.url + '/our_team/' + 'getRegStd', bodyFormData)
      .pipe();
  }

  public viewProdPaging(pageIndex, ddl, brn, lang, type) {
    const formData = new FormData();
    formData.append('pageIndex', pageIndex);
    formData.append('ddl', ddl);
    formData.append('Brn', brn);
    formData.append('LANG', lang);
    formData.append('TYPE', type);
    return this.http
      .post(this.urlApi.url + '/our_team/pagging', formData)
      .pipe();
  }

  public viewPaging(
    pageIndex,
    ddl,
    brn,
    lang,
    type,
    procName,
    lvlName,
    lvlDate
  ) {
    const formData = new FormData();
    formData.append('pageIndex', pageIndex);
    formData.append('ddl', ddl);
    formData.append('Brn', brn);
    formData.append('LANG', lang);
    formData.append('TYPE', type);
    formData.append('procName', procName);
    formData.append('lvlName', lvlName);
    formData.append('lvlDate', lvlDate);
    return this.http
      .post(this.urlApi.url + '/our_team/pagging', formData)
      .pipe();
  }

  public getPost(
    check: string,
    PostID: string,
    Lang: string,
    FbrnId: string,
    Type: string,
    ParentId: string,
    PostName: string,
    Pno: string
  ) {
    const bodyFormData = new FormData();
    bodyFormData.append('check', check);
    bodyFormData.append('Lang', Lang);
    bodyFormData.append('PostId', PostID);
    bodyFormData.append('FbrnId', FbrnId);
    bodyFormData.append('Type', Type);
    bodyFormData.append('ParentId', ParentId);
    bodyFormData.append('PostName', PostName);
    bodyFormData.append('Pno', Pno);
    return this.http
      .post(this.urlApi.url + '/our_team/getPost', bodyFormData)
      .pipe();
  }
  public urlBrn = 'https://api2.gic-edu.net:444/our_team/getMainSys';
  public getPostBrn(check: string, Lang: string, FbrnId: string) {
    const bodyFormData = new FormData();
    bodyFormData.append('check', check);
    bodyFormData.append('Lang', Lang);
    bodyFormData.append('FbrnId', FbrnId);
    return this.http.post<any>(this.urlBrn, bodyFormData).pipe();
  }
}
