import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CryptService } from 'src/app/services/crypt.service';
import { OurTeamService } from 'src/app/services/our-team/our-team.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-show',
  templateUrl: './show.component.html',
  styleUrls: ['./show.component.css'],
})
export class ShowComponent implements OnInit {
  date: any = new Date();
  fileName = 'ExcelSheet.xlsx';
  indexFirst: number;

  selectedYear = '';
  selectedServices = '';
  selectedCourse = '';
  selectedDepartment = '';
  selectedSpecialization = '';
  getYear: any;

  searchService: string;
  searchCourse: string;
  searchDate: string;
  searchSpecial: string;

  ShowData: any = [];
  NoData = '';
  dataSourceServices: any = [];
  dataSourceCountry: any = [];
  dataSourceCources: any = [];
  dataSourceDepartments: any = [];
  dataSourceSpecializations: any = [];
  mainBrn: any[] = [];
  array: any = [];
  array2: any = [];
  Program: any = [];
  translate: 'yes';

  rowsPage: number;
  TableType: number;
  pageNumbers: any = [];
  LANG_ = this.c.Decrypt(sessionStorage.getItem('syslang'));
  BRN_ = this.c.Decrypt(sessionStorage.getItem('brnId'));
  TYPE_ = '';
  TYPE_2 = '';
  parentID = '';
  procName = '';
  TYPE_Name: any;
  NoRecords: boolean = false;

  stringified: any;
  serializedDate = new FormControl(new Date().toISOString());

  serviceCtrl = new FormControl();
  countryCtrl = new FormControl();
  courcesCtrl = new FormControl();
  filteredService: Observable<string[]>;
  filteredCountry: Observable<string[]>;
  filteredCources: Observable<string[]>;

  year = new FormControl('');
  yearsArr = Array.from(Array(new Date().getFullYear() - 2022 + 1).keys()).map(
    (i) => 2022 + i
  );

  constructor(
    private c: CryptService,
    private getData: OurTeamService,
    private _Activatedroute: ActivatedRoute
  ) {
    this._Activatedroute.paramMap.subscribe((params) => {
      this.TYPE_Name = this._Activatedroute.snapshot.paramMap.get('typeName');

      this.ngOnInit();
    });
  }

  myForm: FormGroup;

  //Function To Filtering Services Data
  private _filterServices(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.dataSourceServices.filter((state) =>
      state.postTitle.toLowerCase().includes(filterValue)
    );
  }
  //Function To Filtering Country Data
  private _filterCountry(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.dataSourceCountry.filter((state) =>
      state.postTitle.toLowerCase().includes(filterValue)
    );
  }

  //Function To Filtering Cources Data
  // (state) => state.postTitle.toLowerCase().indexOf(filterValue) === 0
  private _filterCourses(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.dataSourceCources.filter((state) =>
      state.postTitle.toLowerCase().includes(filterValue)
    );
  }

  ngOnInit(): void {
    this.getRows('1');
    this.searchService = '';
    this.searchCourse = '';

    this.NoRecords = false;

    this.myForm = new FormGroup({
      presentDate: new FormControl(new Date().toISOString().substring(0, 10)),
    });

    if (this.TYPE_Name == 'services') {
      this.TableType = 1;
      this.getDropDownServicesData();
      this.getData
        .getServices('sp', '', '', '', '', '', '', '', '', this.BRN_, '')
        .subscribe((data) => {
          this.ShowData = data;
        });
    } else if (this.TYPE_Name == 'courses') {
      this.TableType = 2;
      this.getDropDownCountry();
      this.getData
        .getRegStd('sp', '', '', '', '', '', '', '', '', '')
        .subscribe((data) => {
          this.ShowData = data;
        });
    } else {
      this.TableType = 3;
      this.getDropDownDepartmentData();
      this.getData
        .getRegStd2(
          'sp',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          ''
        )
        .subscribe((data) => {
          this.ShowData = data;
        });
    }
  }

  getRows(num) {
    if (this.TYPE_Name == 'services') {
      this.procName = 'service';
    } else {
      this.procName = 'stdReg';
    }
    if (this.rowsPage == null) this.rowsPage = 10;
    this.getData
      .viewPaging(
        num,
        this.rowsPage,
        this.BRN_,
        this.LANG_,
        this.TYPE_,
        this.procName,
        '',
        ''
      )
      .subscribe((data) => {
        this.ShowData = data['data1'];
        this.indexFirst = data['data1'][0]['displayOrder'];
        this.pageNumbers = data['data2'];
      });
    // this.ShowOrder = true
  }
  openPanelOfServices() {
    this.filteredService = this.serviceCtrl.valueChanges.pipe(
      startWith(''),
      map((servie) =>
        servie ? this._filterServices(servie) : this.dataSourceServices.slice()
      )
    );
  }
  openPanelOfCountry() {
    this.filteredCountry = this.countryCtrl.valueChanges.pipe(
      startWith(''),
      map((country) =>
        country ? this._filterCountry(country) : this.dataSourceCountry.slice()
      )
    );
  }
  openPanelOfCourses() {
    this.filteredCources = this.courcesCtrl.valueChanges.pipe(
      startWith(''),
      map((course) =>
        course ? this._filterCourses(course) : this.dataSourceCources.slice()
      )
    );
  }
  getPage(event: any) {
    // this.ShowOrder = true
    if (this.TYPE_Name == 'services') {
      this.procName = 'service';
    } else {
      this.procName = 'stdReg';
    }
    this.rowsPage = event.target.value;
    this.getData
      .viewPaging(
        1,
        this.rowsPage,
        this.BRN_,
        this.LANG_,
        this.TYPE_,
        this.procName,
        '',
        ''
      )
      .subscribe((data) => {
        this.ShowData = data['data1'];
        this.pageNumbers = data['data2'];
      });
  }
  //Function To Export Table Data To Excel
  btnExport(): void {
    /* pass here the table id */
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
  }

  //Function To Get Countries
  getDropDownCountry() {
    this.getData
      .getPost(
        'getPost',
        '',
        this.LANG_,
        this.BRN_,
        'Univ.Country',
        'NTROOT0',
        '',
        '-1'
      )
      .subscribe((data) => {
        this.dataSourceCountry = data;
        let arr = data as any[];
        this.parentID = arr[0]['postID'];
        this.getDropDownCourcesData(this.parentID);
      });
  }

  //Function To Get Services
  getDropDownServicesData() {
    this.getData
      .getPost(
        'getPost',
        '',
        this.LANG_,
        this.BRN_,
        'Univ.OtherServices',
        'NTROOT0',
        '',
        '-1'
      )
      .subscribe((data) => {
        this.dataSourceServices = data;
      });
  }
  //Function To Get Cources
  getDropDownCourcesData(id: string) {
    this.dataSourceCources = [];
    this.filteredCources = of(<any>[]);
    this.getData
      .getPost(
        'getPost',
        '',
        this.LANG_,
        this.BRN_,
        'Univ.Cources',
        id,
        '',
        '-1'
      )
      .subscribe((data) => {
        this.dataSourceCources = data;
      });
    this.filteredCources = this.courcesCtrl.valueChanges.pipe(
      startWith(''),
      map((course) =>
        course ? this._filterCourses(course) : this.dataSourceCources.slice()
      )
    );
  }

  //Function To Get Department
  getDropDownDepartmentData() {
    this.getData.getPostBrn('getPost', this.LANG_, 'U').subscribe((data) => {
      this.mainBrn = data['mainBrn'];
      this.mainBrn.forEach((x) => {
        if (x.brnSys == 'Univ.Departments') {
          this.dataSourceDepartments.push({
            BrnId: x.brnId,
            DeptName: x.brnName,
          });
        }
      });
    });
  }
  getAll() {
    this.getData
      .getRegStd2(
        'sp',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        ''
      )
      .subscribe((data) => {
        this.ShowData = data;
      });
    this.NoRecords = false;
  }
  getAllServices() {
    this.NoRecords = false;
    this.getData
      .getServices('sp', '', '', '', '', '', '', '', '', this.BRN_, '')
      .subscribe((data) => {
        this.ShowData = data;
      });
  }
  getAllCountries_Courses() {
    this.getData
      .getRegStd('sp', '', '', '', '', '', '', '', '', '')
      .subscribe((data) => {
        this.ShowData = data;
      });
    this.NoRecords = false;
  }
  getValue(value: any) {
    this.selectedServices = value;
  }
  getValueOfCourse(value: any) {
    this.selectedCourse = value;
  }
  //Function To Get Specialization
  onChangeDepartment() {
    this.array = [];
    this.array2 = [];

    this.getData.getPostBrn('getPost', this.LANG_, 'U').subscribe((data) => {
      this.mainBrn = data['mainBrn'];

      this.getData
        .getPost(
          'getPostDetails',
          '',
          this.LANG_,
          '',
          'Univ.Departments.Home.Program',
          '',
          '',
          '-1'
        )
        .subscribe((data2) => {
          let arr2 = data2 as [];

          arr2.forEach((el2) => {
            if (this.selectedDepartment == el2['brnID']) {
              this.array.push({
                postTitle: el2['postTitle'],
              });
            }
          });
        });
      this.dataSourceSpecializations = this.array;
    });
    this.array = [];
  }

  //Function To Get The Date From DatePiker
  updateDate(event: any) {
    this.searchDate = '';
    this.stringified = event.value;
    if (this.stringified.getDate() < 10) {
      this.searchDate =
        this.stringified.getFullYear() +
        '-' +
        (this.stringified.getMonth() + 1) +
        '-0' +
        this.stringified.getDate();
    } else {
      this.searchDate =
        this.stringified.getFullYear() +
        '-' +
        (this.stringified.getMonth() + 1) +
        '-' +
        this.stringified.getDate();
    }
  }

  filtering(title: any, year: string) {
    if (this.TYPE_Name == 'services') {
      this.getData
        .getServices('sp', '', '', '', '', '', '', '', '', '', '')
        .subscribe((data) => {
          this.ShowData = data;
          let records = [];
          this.ShowData.filter((record) => record.service === title).forEach(
            (record) => records.push(record)
          );
          this.ShowData = [];
          if (records.length == 0) {
            this.NoRecords = true;
          } else {
            records.forEach((el2) => {
              this.NoRecords = false;
              this.ShowData.push({
                com_name: el2['com_name'],
                com_domain: el2['com_domain'],
                com_phone: el2['com_phone'],
                com_email: el2['com_email'],
                service: el2['service'],
                city: el2['city'],
                service_disc: el2['service_disc'],
              });
            });
          }
        });
    } else if (this.TYPE_Name == 'courses') {
      this.getData
        .getRegStd('sp', '', '', '', '', '', '', '', '', '')
        .subscribe((data) => {
          this.ShowData = data;
          let records = [];
          if (title != '' && year != undefined) {
            console.log('1');

            this.ShowData.filter(
              (record) => record.lvl_name === title && record.lvl_date == year
            ).forEach((record) => records.push(record));
            this.ShowData = [];
            if (records.length == 0) {
              this.NoRecords = true;
            } else {
              this.NoRecords = false;
              records.forEach((el2) => {
                this.ShowData.push({
                  lvl_name: el2['lvl_name'],
                  lvl_date: el2['lvl_date'],
                  std_name: el2['std_name'],
                  std_phone: el2['std_phone'],
                  std_email: el2['std_email'],
                });
              });
            }
          } else if (title != '' && year == undefined) {
            console.log('nodate');
            this.ShowData.filter((record) => record.lvl_name === title).forEach(
              (record) => records.push(record)
            );
            this.ShowData = [];
            if (records.length == 0) {
              this.NoRecords = true;
            } else {
              this.NoRecords = false;
              records.forEach((el2) => {
                this.ShowData.push({
                  lvl_name: el2['lvl_name'],
                  lvl_date: el2['lvl_date'],
                  std_name: el2['std_name'],
                  std_phone: el2['std_phone'],
                  std_email: el2['std_email'],
                });
              });
            }
          } else if (title == '' && year != undefined) {
            console.log('notitle');
            this.ShowData.filter((record) => record.lvl_date == year).forEach(
              (record) => records.push(record)
            );
            this.ShowData = [];
            if (records.length == 0) {
              this.NoRecords = true;
            } else {
              this.NoRecords = false;
              records.forEach((el2) => {
                this.ShowData.push({
                  lvl_name: el2['lvl_name'],
                  lvl_date: el2['lvl_date'],
                  std_name: el2['std_name'],
                  std_phone: el2['std_phone'],
                  std_email: el2['std_email'],
                });
              });
            }
          } else {
            alert('Enter');
            this.getAllCountries_Courses();
          }
        });
    } else {
      this.getData
        .getRegStd2(
          'sp',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          ''
        )
        .subscribe((data) => {
          this.ShowData = data;
          let records = [];
          if (title != '' && year != '') {
            this.ShowData.filter(
              (record) =>
                record.std_lvl_main === title &&
                record.c_date.slice(0, 4) == year
            ).forEach((record) => records.push(record));
            this.ShowData = [];
            if (records.length > 0) {
              this.NoRecords = false;
              records.forEach((el2) => {
                this.ShowData.push({
                  std_name: el2['std_name'],
                  std_gender: el2['std_gender'],
                  std_nationality: el2['std_nationality'],
                  std_place_birthday: el2['std_place_birthday'],
                  std_date_birthday: el2['std_date_birthday'],
                  std_type_qualification: el2['std_type_qualification'],
                  std_average: el2['std_average'],
                  std_date_qualification: el2['std_date_qualification'],
                  std_place_qualification: el2['std_place_qualification'],
                  std_governorate: el2['std_governorate'],
                  std_school: el2['std_school'],
                  std_place_issue: el2['std_place_issue'],
                  std_date_issue: el2['std_date_issue'],
                  std_type_study: el2['std_type_study'],
                  std_phone: el2['std_phone'],
                  std_address: el2['std_address'],
                  std_email: el2['std_email'],
                  std_lvl_main: el2['std_lvl_main'],
                  std_type_id_card: el2['std_type_id_card'],
                  std_id_card_number: el2['std_id_card_number'],
                  std_palce_id_card: el2['std_palce_id_card'],
                });
              });
            } else {
              this.NoRecords = true;
            }
          } else if (title != '' && year == '') {
            this.ShowData.filter(
              (record) => record.std_lvl_main === title
            ).forEach((record) => records.push(record));
            this.ShowData = [];
            if (records.length > 0) {
              this.NoRecords = false;
              records.forEach((el2) => {
                this.ShowData.push({
                  std_name: el2['std_name'],
                  std_gender: el2['std_gender'],
                  std_nationality: el2['std_nationality'],
                  std_place_birthday: el2['std_place_birthday'],
                  std_date_birthday: el2['std_date_birthday'],
                  std_type_qualification: el2['std_type_qualification'],
                  std_average: el2['std_average'],
                  std_date_qualification: el2['std_date_qualification'],
                  std_place_qualification: el2['std_place_qualification'],
                  std_governorate: el2['std_governorate'],
                  std_school: el2['std_school'],
                  std_place_issue: el2['std_place_issue'],
                  std_date_issue: el2['std_date_issue'],
                  std_type_study: el2['std_type_study'],
                  std_phone: el2['std_phone'],
                  std_address: el2['std_address'],
                  std_email: el2['std_email'],
                  std_lvl_main: el2['std_lvl_main'],
                  std_type_id_card: el2['std_type_id_card'],
                  std_id_card_number: el2['std_id_card_number'],
                  std_palce_id_card: el2['std_palce_id_card'],
                });
              });
            } else {
              this.NoRecords = true;
            }
          } else if (title == '' && year != '') {
            this.ShowData.filter(
              (record) => record.c_date.slice(0, 4) == year
            ).forEach((record) => records.push(record));
            this.ShowData = [];
            if (records.length > 0) {
              this.NoRecords = false;
              records.forEach((el2) => {
                this.ShowData.push({
                  std_name: el2['std_name'],
                  std_gender: el2['std_gender'],
                  std_nationality: el2['std_nationality'],
                  std_place_birthday: el2['std_place_birthday'],
                  std_date_birthday: el2['std_date_birthday'],
                  std_type_qualification: el2['std_type_qualification'],
                  std_average: el2['std_average'],
                  std_date_qualification: el2['std_date_qualification'],
                  std_place_qualification: el2['std_place_qualification'],
                  std_governorate: el2['std_governorate'],
                  std_school: el2['std_school'],
                  std_place_issue: el2['std_place_issue'],
                  std_date_issue: el2['std_date_issue'],
                  std_type_study: el2['std_type_study'],
                  std_phone: el2['std_phone'],
                  std_address: el2['std_address'],
                  std_email: el2['std_email'],
                  std_lvl_main: el2['std_lvl_main'],
                  std_type_id_card: el2['std_type_id_card'],
                  std_id_card_number: el2['std_id_card_number'],
                  std_palce_id_card: el2['std_palce_id_card'],
                });
              });
            } else {
              this.NoRecords = true;
            }
          } else {
            this.getAll();
          }
        });
    }
  }
}
