<mat-card class="example-card" style="margin-top: 10px">
  <mat-card-header>
    <mat-card-title *ngIf="TableType == 1">{{
      "show.RegInSrv" | translate
    }}</mat-card-title>
    <mat-card-title *ngIf="TableType == 2">{{
      "show.RedInCourse" | translate
    }}</mat-card-title>
    <mat-card-title *ngIf="TableType == 3">{{
      "show.RegOrders" | translate
    }}</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <mat-card class="example-card">
      <mat-card-header>
        <mat-card-title>
          {{ "show.Show" | translate }}
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="row">
          <div *ngIf="TableType == 1" class="col-lg-9 col-sm-12">
            <form class="example-form">
              <mat-form-field class="example-full-width" appearance="fill">
                <mat-label>{{ "show.Service" | translate }}</mat-label>
                <input
                  type="text"
                  matInput
                  [formControl]="serviceCtrl"
                  [matAutocomplete]="autosrv"
                  autocomplete="anyword"
                  [matAutocompleteTrigger]="autosrv"
                  (click)="openPanelOfServices()"
                />
                <mat-autocomplete
                  #autosrv="matAutocomplete"
                  (optionSelected)="getValue($event.option.value)"
                >
                  <mat-option (click)="getAllServices()">{{
                    "show.All" | translate
                  }}</mat-option>
                  <mat-option
                    *ngFor="let srv of filteredService | async"
                    [value]="srv.postTitle"
                  >
                    <span>{{ srv.postTitle }}</span>
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </form>
          </div>
          <div *ngIf="TableType == 2" class="col-lg-3 col-sm-12">
            <form class="example-form">
              <mat-form-field class="example-full-width" appearance="fill">
                <mat-label>{{ "show.Country" | translate }}</mat-label>
                <input
                  type="text"
                  matInput
                  [formControl]="countryCtrl"
                  [matAutocomplete]="countryauto"
                  (click)="openPanelOfCountry()"
                />
                <mat-autocomplete #countryauto="matAutocomplete">
                  <mat-option (click)="(getAllCountries_Courses)">{{
                    "show.All" | translate
                  }}</mat-option>
                  <mat-option
                    *ngFor="let country_ of filteredCountry | async"
                    [value]="country_.postTitle"
                    (click)="getDropDownCourcesData(country_.postID)"
                  >
                    <span>{{ country_.postTitle }}</span>
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </form>
          </div>
          <div *ngIf="TableType == 2" class="col-lg-5 col-sm-12">
            <form class="example-form">
              <mat-form-field class="example-full-width" appearance="fill">
                <mat-label>{{ "show.CourseName" | translate }}</mat-label>
                <input
                  type="text"
                  matInput
                  [formControl]="courcesCtrl"
                  [matAutocomplete]="courseauto"
                  (click)="openPanelOfCourses()"
                />
                <mat-autocomplete
                  #courseauto="matAutocomplete"
                  (optionSelected)="getValueOfCourse($event.option.value)"
                >
                  <mat-option
                    *ngFor="let course of filteredCources | async"
                    [value]="course.postTitle"
                  >
                    <span>{{ course.postTitle }}</span>
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </form>
          </div>
          <div *ngIf="TableType == 2" class="col-lg-4 col-sm-12">
            <mat-form-field appearance="fill">
              <mat-label style="float: right">{{
                "show.Choose" | translate
              }}</mat-label>
              <input
                matInput
                [matDatepicker]="picker"
                (dateChange)="updateDate($event)"
              />
              <mat-hint>MM/DD/YYYY</mat-hint>
              <mat-datepicker-toggle
                class="mat-toggle"
                matIconSuffix
                [for]="picker"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </div>

          <!-- <div *ngIf="TableType == 3" class="col-lg-3 col-sm-12">
            <form class="example-form">
              <mat-form-field class="example-full-width" appearance="fill">
                <mat-label>{{ "show.Department" | translate }}</mat-label>
                <input
                  type="text"
                  matInput
                  [formControl]="departmentCtrl"
                  [matAutocomplete]="departmentauto"
                />
                <mat-autocomplete #departmentauto="matAutocomplete">
                  <mat-option
                    *ngFor="let department_ of filteredDepartment | async"
                    [value]="department_.DeptName"
                    (click)="getDropDownSpecializationData(department_.BrnId)"
                  >
                    <span>{{ department_.DeptName }}</span>
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </form>
          </div>
          <div *ngIf="TableType == 3" class="col-lg-4 col-sm-12">
            <form class="example-form">
              <mat-form-field class="example-full-width" appearance="fill">
                <mat-label>{{ "show.LvlMain" | translate }}</mat-label>
                <input
                  type="text"
                  matInput
                  [formControl]="SpecializationCtrl"
                  [matAutocomplete]="specializationauto"
                />
                <mat-autocomplete
                  #specializationauto="matAutocomplete"
                  (optionSelected)="filtering($event.option.value)"
                >
                  <mat-option
                    *ngFor="let special of filteredSpecialization | async"
                    [value]="special.postTitle"
                  >
                    <span>{{ special.postTitle }}</span>
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </form>
          </div> -->
          <div *ngIf="TableType == 3" class="col-lg-2 col-sm-12">
            <mat-form-field
              appearance="fill"
              style="width: 100%; margin: 0; margin-right: 10px"
            >
              <mat-label>{{ "show.regyear" | translate }}</mat-label>
              <mat-select [formControl]="year" [(value)]="selectedYear">
                <mat-option *ngFor="let y of yearsArr" [value]="y">{{
                  y
                }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div *ngIf="TableType == 3" class="col-lg-5 col-sm-12">
            <mat-form-field
              appearance="fill"
              style="width: 100%; margin: 0; margin-right: 10px"
            >
              <mat-label>{{ "show.Department" | translate }}</mat-label>
              <mat-select
                (valueChange)="onChangeDepartment()"
                matNativeControl
                [(value)]="selectedDepartment"
              >
                <mat-option (click)="getAll()">{{
                  "show.All" | translate
                }}</mat-option>
                <mat-option
                  *ngFor="let dept of dataSourceDepartments"
                  [value]="dept.BrnId"
                >
                  {{ dept.DeptName }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div *ngIf="TableType == 3" class="col-lg-5 col-sm-12">
            <mat-form-field
              appearance="fill"
              style="width: 100%; margin: 0; margin-right: 10px"
            >
              <mat-label>{{ "show.LvlMain" | translate }}</mat-label>
              <mat-select matNativeControl [(value)]="selectedSpecialization">
                <mat-option>{{ "show.chooseSpecial" | translate }}</mat-option>
                <mat-option
                  *ngFor="let special of dataSourceSpecializations"
                  [value]="special.postTitle"
                >
                  {{ special.postTitle }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </mat-card-content>

      <mat-card-actions>
        <p style="text-align: center">
          <button
            *ngIf="TableType == 1"
            style="margin-left: 10px"
            style="
              bottom: 54%;
              margin-left: 10px;
              background-color: blue;
              z-index: 2;
            "
            matTooltip="عرض"
            class="float"
            mat-fab
            color="primary"
            (click)="filtering(selectedServices, '')"
          >
            {{ "btn.show" | translate }}
          </button>
          <button
            *ngIf="TableType == 2"
            style="margin-left: 10px"
            style="
              bottom: 54%;
              margin-left: 10px;
              background-color: blue;
              z-index: 2;
            "
            matTooltip="عرض"
            class="float"
            mat-fab
            color="primary"
            (click)="filtering(selectedCourse, searchDate)"
          >
            {{ "btn.show" | translate }}
          </button>
          <button
            *ngIf="TableType == 3"
            style="margin-left: 10px"
            style="
              bottom: 54%;
              margin-left: 10px;
              background-color: blue;
              z-index: 2;
            "
            matTooltip="عرض"
            class="float"
            mat-fab
            color="primary"
            (click)="filtering(selectedSpecialization, selectedYear)"
          >
            {{ "btn.show" | translate }}
          </button>

          <button
            matTooltip="تصدير"
            style="
              bottom: 44%;
              margin-left: 10px;
              background-color: orangered;
              z-index: 2;
            "
            class="float"
            mat-fab
            color="primary"
            (click)="btnExport()"
          >
            {{ "btn.export" | translate }}
          </button>
        </p>
      </mat-card-actions>
    </mat-card>

    <div style="margin: 20px">
      <p style="text-align: center">
        {{ "btn.show" | translate }}

        <select (change)="getPage($event)">
          <option value="10">10</option>
          <option value="25">25</option>
          <option value="50">50</option>
          <option value="100">100</option>
          <option value="250">250</option>
          <option value="500">500</option>
        </select>
      </p>
      <table
        *ngIf="TableType == 1"
        id="excel-table"
        style="
          width: 100%;
          max-width: 100%;
          margin-bottom: 1.143rem;
          background-color: transparent;
        "
        class="table table-bordered table-striped"
      >
        <thead>
          <tr>
            <th style="text-align: center" scope="col">#</th>
            <th style="text-align: center" scope="col">
              {{ "show.OrganizationName" | translate }}
            </th>
            <th style="text-align: center" scope="col">
              {{ "show.Specialization" | translate }}
            </th>
            <th style="text-align: center" scope="col">
              {{ "show.PhoneNumber" | translate }}
            </th>
            <th style="text-align: center" scope="col">
              {{ "show.YourEmail" | translate }}
            </th>
            <th style="text-align: center" scope="col">
              {{ "show.Service" | translate }}
            </th>
            <th style="text-align: center" scope="col">
              {{ "show.City" | translate }}
            </th>
            <th style="text-align: center" scope="col">
              {{ "show.ServiceDescription" | translate }}
            </th>
          </tr>
        </thead>
        <tbody cdkDropList style="text-align: center !important">
          <tr *ngFor="let item of ShowData; let i = index" cdkDrag>
            <th scope="row">{{ i + 1 }}</th>
            <td>{{ item.com_name }}</td>
            <td>{{ item.com_domain }}</td>
            <td>{{ item.com_phone }}</td>
            <td>{{ item.com_email }}</td>
            <td>{{ item.service }}</td>
            <td>{{ item.city }}</td>
            <td>{{ item.service_disc }}</td>
          </tr>
        </tbody>
      </table>

      <table
        *ngIf="TableType == 2"
        id="excel-table"
        style="
          width: 100%;
          max-width: 100%;
          margin-bottom: 1.143rem;
          background-color: transparent;
        "
        class="table table-bordered table-striped"
      >
        <thead>
          <tr>
            <th style="padding: 3px; text-align: center" scope="col">#</th>
            <th style="padding: 3px; text-align: center" scope="col">
              {{ "show.CourseName" | translate }}
            </th>
            <th style="padding: 3px; text-align: center" scope="col">
              {{ "show.date" | translate }}
            </th>
            <th style="padding: 3px; text-align: center" scope="col">
              {{ "show.stdName" | translate }}
            </th>
            <th style="padding: 3px; text-align: center" scope="col">
              {{ "show.PhoneNumber" | translate }}
            </th>
            <th style="padding: 3px; text-align: center" scope="col">
              {{ "show.YourEmail" | translate }}
            </th>
          </tr>
        </thead>
        <tbody cdkDropList style="text-align: center !important">
          <tr *ngFor="let item of ShowData; let i = index" cdkDrag>
            <th style="padding: 3px" scope="row">{{ i + 1 }}</th>
            <td style="padding: 3px">{{ item.lvl_name }}</td>
            <td style="padding: 3px">{{ item.lvl_date }}</td>
            <td style="padding: 3px">{{ item.std_name }}</td>
            <td style="padding: 3px">{{ item.std_phone }}</td>
            <td style="padding: 3px">{{ item.std_email }}</td>
          </tr>
        </tbody>
      </table>
      <div style="overflow-x: auto; padding: 0 0 0 2px">
        <table
          *ngIf="TableType == 3"
          id="excel-table"
          style="
            width: max-content;
            max-width: max-content;
            margin-bottom: 1.143rem;
            background-color: transparent;
          "
          class="table table-bordered table-striped"
        >
          <thead>
            <tr>
              <th style="text-align: center" scope="col">#</th>
              <th style="text-align: center" scope="col">
                {{ "show.stdName" | translate }}
              </th>
              <th style="text-align: center" scope="col">
                {{ "show.Gender" | translate }}
              </th>
              <th style="text-align: center" scope="col">
                {{ "show.Nationality" | translate }}
              </th>
              <th style="text-align: center" scope="col">
                {{ "show.BirthPlace" | translate }}
              </th>
              <th style="text-align: center" scope="col">
                {{ "show.BirthDate" | translate }}
              </th>
              <th style="text-align: center" scope="col">
                {{ "show.QualificationType" | translate }}
              </th>
              <th style="text-align: center" scope="col">
                {{ "show.Average" | translate }}
              </th>
              <th style="text-align: center" scope="col">
                {{ "show.DateQualification" | translate }}
              </th>
              <th style="text-align: center" scope="col">
                {{ "show.PlaceQualification" | translate }}
              </th>
              <!-- <th style="text-align: center" scope="col">
                {{ "show.Governorate" | translate }}
              </th> -->
              <th style="text-align: center" scope="col">
                {{ "show.School" | translate }}
              </th>
              <!-- <th style="text-align: center" scope="col">
                {{ "show.PlaceIssue" | translate }}
              </th>
              <th style="text-align: center" scope="col">
                {{ "show.DateIssue" | translate }}
              </th> -->
              <th style="text-align: center" scope="col">
                {{ "show.StudyType" | translate }}
              </th>
              <th style="text-align: center" scope="col">
                {{ "show.PhoneNumber" | translate }}
              </th>
              <th style="text-align: center" scope="col">
                {{ "show.Address" | translate }}
              </th>
              <th style="text-align: center" scope="col">
                {{ "show.YourEmail" | translate }}
              </th>
              <th style="text-align: center" scope="col">
                {{ "show.LvlMain" | translate }}
              </th>
              <th style="text-align: center" scope="col">
                {{ "show.TypeIdCard" | translate }}
              </th>
              <th style="text-align: center" scope="col">
                {{ "show.IdCardNumber" | translate }}
              </th>
              <th style="text-align: center" scope="col">
                {{ "show.PlaceIdCard" | translate }}
              </th>
            </tr>
          </thead>
          <tbody cdkDropList style="text-align: center !important">
            <tr *ngFor="let item of ShowData; let i = index" cdkDrag>
              <th scope="row">{{ i + 1 }}</th>
              <td>{{ item.std_name }}</td>
              <td>{{ item.std_gender }}</td>
              <td>{{ item.std_nationality }}</td>
              <td>{{ item.std_place_birthday }}</td>
              <td>{{ item.std_date_birthday }}</td>
              <td>{{ item.std_type_qualification }}</td>
              <td>{{ item.std_average }}</td>
              <td>{{ item.std_date_qualification }}</td>
              <td>{{ item.std_place_qualification }}</td>
              <!-- <td >{{ item.std_governorate }}</td> -->
              <td>{{ item.std_school }}</td>
              <!-- <td >{{ item.std_place_issue }}</td>
              <td>{{ item.std_date_issue }}</td> -->
              <td>{{ item.std_type_study }}</td>
              <td>{{ item.std_phone }}</td>
              <td>{{ item.std_address }}</td>
              <td>{{ item.std_email }}</td>
              <td>{{ item.std_lvl_main }}</td>
              <td>{{ item.std_type_id_card }}</td>
              <td>{{ item.std_id_card_number }}</td>
              <td>{{ item.std_palce_id_card }}</td>
            </tr>
          </tbody>
        </table>
        <h2
          *ngIf="NoRecords"
          style="text-align: center; font-family: 'Droid Arabic Kufi'"
        >
          {{ "show.NoRecords" | translate }}
        </h2>
      </div>

      <nav aria-label="Page navigation example">
        <ul class="pagination">
          <li *ngFor="let item of pageNumbers" class="page-item">
            <button (click)="getRows(item.numPage)" class="page-link">
              {{ item.namePage }}
            </button>
          </li>
        </ul>
      </nav>
    </div>
  </mat-card-content>
</mat-card>
